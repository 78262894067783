<template>
  <b-overlay
    :show="$apollo.loading || mutationLoading"
    rounded="sm"
  >
    <!-- search input -->
    <div class="custom-search d-flex justify-content-start">
      <b-form-group
        label="Search"
        label-size="sm"
      >
        <b-form-input
          v-model="searchTerm"
          class="d-inline-block mr-1"
          placeholder="Search startups"
          type="text"
        />
      </b-form-group>
    </div>
    <startups-table
      :columns="columns"
      :get-link="getLink"
      :opportunities-type="'programs'"
      :rows="rows"
      :search-term="searchTerm"
    />
  </b-overlay>
</template>

<script>
import gql from 'graphql-tag'
import StartupsTable from '@/components/startups/StartupsTable.vue'
import { BFormGroup, BFormInput } from 'bootstrap-vue'

export default {
  components: {
    BFormInput,
    BFormGroup,
    StartupsTable,
  },
  data() {
    return {
      mutationLoading: false,
      pageLength: 50,
      rows: [],
      searchTerm: '',
      columns: [
        {
          label: 'Name',
          field(row) {
            return row.title
          },
        },
        {
          label: 'Category',
          field: 'category',
        },
        {
          label: 'Actions',
          field: 'actions',
        },
      ],
    }
  },

  methods: {
    getLink(sid, type) {
      const routeName = type === 'Exercises' ? 'mentor-exercise' : 'mentor-milestone'
      return {
        name: routeName,
        params: {
          id: this.$route.params.id,
          pid: this.$route.params.pid,
          sid,
        },
      }
    },
  },
  apollo: {
    rows: {
      query() {
        return gql`
          query GetStartups($id: Int!, $partnerId: Int!) {
            programs_basicinfo(where: { id: { _eq: $id } }) {
              programs_startupparticipants {
                id
                programs_applicantstable {
                  users_organizationtable {
                    title
                  }
                }
                programs_startupassignmenttables(
                  where: { partner_id: { _eq: $partnerId } }
                  order_by: { id: desc }
                ) {
                  programs_startupparticipant {
                    id
                  }
                }
              }
              programs_partnerstables(where: { id: { _eq: $partnerId } }) {
                is_super
                is_jury
              }
            }
          }
        `
      },
      variables() {
        return {
          id: this.$route.params.id,
          partnerId: this.$route.params.pid,
        }
      },
      update(data) {
        const temp = []
        if (data.programs_basicinfo[0].programs_partnerstables[0].is_super) {
          data.programs_basicinfo[0].programs_startupparticipants.forEach(
            startup => {
              temp.push({
                sid: startup.id,
                title: startup.programs_applicantstable.users_organizationtable.title,
                category: startup.programs_startupassignmenttables.length > 0 ? 'Assigned' : 'Program',
              })
            },
          )
        } else {
          data.programs_basicinfo[0].programs_startupparticipants.forEach(startup => {
            if (startup.programs_startupassignmenttables.length > 0) {
              temp.push({
                sid: startup.id,
                title: startup.programs_applicantstable.users_organizationtable.title,
                category: 'Assigned',
              })
            }
          })
        }
        return temp
      },
    },
  },
}
</script>
